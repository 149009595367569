<template>
Error
</template>

<script setup>
let redirectPath = '/';

if (window.location.pathname === '/nab2022') {
	redirectPath = '/Infocomm2022'
}

clearError({ redirect: redirectPath });
</script>