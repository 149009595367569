import { useState, useGtag } from 'vue-gtag-next';
import { Base64 } from 'js-base64';

type AnalyticsObject = {
	action: string,
	category: string,
	label?: string,
}

type AnalyticsConfig = {
	zenAnalyticsUrl: string,
	gtagId: string,
	appName: string,
	useZenAnalytics: boolean,
	useGtag: boolean,
	heartbeat?: boolean,
	heartbeatInterval?: number,
	tenant: string;
}

const analyticsConfig = ref<AnalyticsConfig>({
	zenAnalyticsUrl: '',
	gtagId: '',
	appName: '',
	useZenAnalytics: true,
	useGtag: false,
	heartbeat: false,
	heartbeatInterval: 60000,
	tenant: '',
});
const thirdPartyEnabled = ref(false);
const heartbeatTimer = ref<ReturnType<typeof setInterval>>();
const isHidden = ref(false);
// const eventsQueue = ref([]);

export const useAnalytics = (config?: AnalyticsConfig) => {
	if (config) {
		analyticsConfig.value = Object.assign({}, analyticsConfig, config);

		if (analyticsConfig.value.useZenAnalytics) {
			sendZenAnalytics({ action: 'PageView', category: 'Home'});
		}

		if (analyticsConfig.value.heartbeat) {
			startHeartBeat();
		}
	}

	function enableAnalytics (isConsent = false): void {
		const { isEnabled } = useState();
		isEnabled!.value = isConsent;
		thirdPartyEnabled.value = isConsent;

		if (analyticsConfig.value.useGtag && isConsent) {
			sendScreenView({ action: 'PageView', category: 'ConsentAllowed' });
		}
	}

	function startHeartBeat () {
		sendZenAnalytics({ action: 'HeartbeatWeb', category: 'Start'});
		document.addEventListener('visibilitychange', (evt) => { handleHiddenTab(evt); });
		window.addEventListener('beforeunload', () => { endHeartBeat(); });

		heartbeatTimer.value = setInterval(() => { 
			if (!isHidden.value) {
				sendZenAnalytics({ action: 'HeartbeatWeb', category: 'Beat'});
			}
		}, analyticsConfig.value.heartbeatInterval);
	}

	function endHeartBeat(): void {
		sendZenAnalytics({ action: 'HeartbeatWeb', category: 'End'});
	}

	function handleHiddenTab (event: Event): void {
		isHidden.value = (event.target as Document).visibilityState === 'hidden';

		if (isHidden.value) {
			sendZenAnalytics({ action: 'HeartbeatWeb', category: 'Pause'});
		} else {
			sendZenAnalytics({ action: 'HeartbeatWeb', category: 'Restart'});
		}
	}

	function sendScreenView (object: AnalyticsObject): void {
		if (thirdPartyEnabled.value && analyticsConfig.value.useGtag) {
			const { screenview } = useGtag();
			const screenName = `${object.action}.${object.category}`;

			screenview({
				app_name: analyticsConfig.value.appName,
				screen_name : screenName,
			});
		}

		if (analyticsConfig.value.useZenAnalytics) {
			sendZenAnalytics(object);
		}
	}

	async function sendZenAnalytics (object: AnalyticsObject): Promise<any> {
		const { getDeviceId, getSessionId } = useAppGuids();
		const eventPayload = [
			{
				subject: {
					type: 'user',
					deviceId: getDeviceId() || '',
					userAgent: window.navigator.userAgent,
					sessionId: getSessionId() || '',
					appReleaseNumber: '1.0',
					userId: '',
					appId: analyticsConfig.value.appName,
				},
				action: {
					type: object.action,
					target: object.category
				},
				eventTime: new Date().toISOString(),
				tenant: analyticsConfig.value.tenant,
			}
		];
		// console.log('eventPayload', eventPayload);

		// eventsQueue

		postZenAnalytics(eventPayload);
	}

	async function postZenAnalytics(eventPayload) {
		const post =  {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Zen-Client': Base64.encode(JSON.stringify(eventPayload[0].subject))
			}
		};

		const payload = { ...post, body: JSON.stringify(eventPayload) };

		await fetch(analyticsConfig.value.zenAnalyticsUrl, payload);
	}

	return {
		enableAnalytics,
		sendScreenView,
	}
}