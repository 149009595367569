export const useAppGuids = () => {
	function getDeviceId() {
		const deviceId = sessionStorage.getItem('deviceId') || undefined;

		if (!deviceId) {
			sessionStorage.setItem('deviceId', getGuid());
		}

		return deviceId;
	}

	function getSessionId() {
		const sessionId = sessionStorage.getItem('sessionId') || undefined;

		if (!sessionId) {
			sessionStorage.setItem('sessionId', getGuid());
		}

		return sessionId;
	}

	function getGuid() {
		let d = new Date().getTime() + performance.now();
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
			const r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
		});
	}

	return {
		getDeviceId,
		getSessionId,
	}
}
