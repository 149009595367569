<script setup lang="ts">
const emit = defineEmits(["closeModal"]);

function onContinue(): void {
  localStorage.setItem("cookieAccepted", "true");
  emit("closeModal");
}
</script>

<template>
  <div class="cookies">
    <div class="container">
      <h1>Zenapptic uses cookies to create a better experience for you</h1>

      <div class="body">
        <p>
          We use cookies on our website to collect information on how you
          interact with the website and to assist with our marketing efforts. By
          clicking "CONTINUE", you agree to the storing of cookies on your
          computer. For more information, please see our
          <a href="">Privacy Policy</a>.
        </p>

        <p class="text-right">
          <a class="button" @click="onContinue">CONTINUE</a>
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../assets/scss/_vars";
.cookies {
  position: fixed;
  width: 100vw;
  z-index: 1000;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;

  .container {
    color: white;
    max-width: 1000px;
    padding: 3rem 2rem 1.5rem 2rem;
    width: 100%;
    background: rgb(28, 24, 25);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0px 2px 10px 2px rgba(white, 0.3);
    transform: translateY(100%);
    animation: enterScreen 0.75s forwards;

    @keyframes enterScreen {
      0% {
        transform: translateY(100%);
      }

      100% {
        transform: translateY(0);
      }
    }

    @media only screen and (max-width: 767px) {
      bottom: 70px;
    }

    h1 {
      text-align: center;
      font-weight: 600;
      text-transform: uppercase;
      font-size: min(5vw, 2.2rem);
    }

    .body {
      p {
        font-size: min(3vw, 1.5rem);
        padding-left: 1rem;
        padding-bottom: 1rem;
      }

      a {
        color: #fff;
      }

      a.button {
        padding: 0.75rem 1.25rem;
        background: $primary-color;
        color: black;
        font-size: min(4vw, 1.6rem);
        border: 0;
        border-radius: 10px;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.25s;
        border: 2px $primary-color solid;

        &:hover {
          background: transparent;
          color: $primary-color;
        }
      }
    }
  }
}
</style>
