<script setup lang="ts">
import { useRoute } from "vue-router";
import { useScriptTag } from "@vueuse/core";

const route = useRoute();
const cookiesModalOpen = ref(false);
const nuxtApp = useNuxtApp();
const config = useRuntimeConfig().public;

const analyticsConfig = {
  zenAnalyticsUrl: config.zenAnalytics,
  gtagId: config.gaTagIds,
  appName: config.appName,
  useZenAnalytics: config.useZenAnalytics as unknown as boolean,
  useGtag: config.useGtag as unknown as boolean,
  tenant: config.appTenant,
};

const { getCdnUrl } = useEnvPaths();

useHead({
  title: "Zenapptic AI  - Visual experience platform",
  htmlAttrs: {
    lang: "en",
  },
  link: [
    { rel: "icon", type: "image/x-icon", href: `${getCdnUrl()}/favicon.png` },
  ],
  meta: [
    { charset: "utf-8" },
    { "http-equiv": "x-ua-compatiable", content: "IE=edge" },
    {
      name: "viewport",
      content:
        "width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=0",
    },
    {
      hid: "description",
      name: "description",
      content:
        "Zenapptic provides an AI-powered, real-time platform that drives visual experiences at work and at play.",
    },
    {
      hid: "og:title",
      name: "og:title",
      content: "Zenapptic Ai",
    },
    {
      hid: "og:description",
      name: "og:description",
      content:
        "Zenapptic provides an AI-powered, real-time platform that drives visual experiences at work and at play.",
    },
    {
      hid: "og:url",
      name: "og:url",
      content: "https://www.zenapptic.ai",
    },
    {
      hid: "og:site_name",
      name: "og:site_name",
      content: "Zenapptic Ai",
    },
    {
      hid: "og:image",
      name: "og:image",
      content: `${getCdnUrl()}/zen-social-card.jpeg`,
    },
    {
      hid: "twitter:card",
      name: "twitter:card",
      content: `${getCdnUrl()}/zen-social-card.jpeg`,
    },
    {
      hid: "twitter:image:alt",
      name: "twitter:image:alt",
      content: "Zenapptic Ai",
    },
  ],
});

nuxtApp.hook("page:finish", () => {
  window.scrollTo(0, 0);
});

const { load } = useScriptTag(`${getCdnUrl()}/assets/js/feeder.js`, () => {}, {
  manual: true,
});

const { load: loadConfig } = useScriptTag(
  `${getCdnUrl()}/assets/js/feeder-config.js`,
  () => {},
  {
    manual: true,
  }
);

onMounted(async () => {
  const { enableAnalytics } = useAnalytics(analyticsConfig);

  if (!localStorage.getItem("cookieAccepted")) {
    openCookiesModal();
  } else {
    enableAnalytics(!!localStorage.getItem("cookieAccepted"));

    await loadConfig();
    load();
  }
});

function openCookiesModal() {
  cookiesModalOpen.value = true;
}

async function closeModal() {
  const { enableAnalytics } = useAnalytics();
  cookiesModalOpen.value = false;
  enableAnalytics(true);
  await loadConfig();
  load();
}

onUnmounted(() => {
  const { sendScreenView } = useAnalytics();
  sendScreenView({
    action: "UnloadEvent",
    category: "exit",
  });
});
</script>

<template>
  <div class="app-wrapper">
    <TheNavBar v-if="route.path !== '/creative-services'" />
    <NuxtPage />
    <TheFooter />

    <Cookies v-if="cookiesModalOpen" @close-modal="closeModal" />
  </div>
</template>
