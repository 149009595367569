import { default as contact_45ustGcxzGiSEKMeta } from "/home/vsts/work/1/s/zenapptic-website-nuxt/pages/contact-us.vue?macro=true";
import { default as creative_45serviceszLVk2IO6hoMeta } from "/home/vsts/work/1/s/zenapptic-website-nuxt/pages/creative-services.vue?macro=true";
import { default as indexoqeRwuFrbnMeta } from "/home/vsts/work/1/s/zenapptic-website-nuxt/pages/index.vue?macro=true";
import { default as privacy5lYVuh5HrtMeta } from "/home/vsts/work/1/s/zenapptic-website-nuxt/pages/privacy.vue?macro=true";
import { default as digital_45workplace2WEEK2ItdSMeta } from "/home/vsts/work/1/s/zenapptic-website-nuxt/pages/solutions/digital-workplace.vue?macro=true";
import { default as experience_45centersblftJQQQHnMeta } from "/home/vsts/work/1/s/zenapptic-website-nuxt/pages/solutions/experience-centers.vue?macro=true";
import { default as experiential_45spaceszDnjFaQo1mMeta } from "/home/vsts/work/1/s/zenapptic-website-nuxt/pages/solutions/experiential-spaces.vue?macro=true";
import { default as virtual_45productionKUH9koiGYQMeta } from "/home/vsts/work/1/s/zenapptic-website-nuxt/pages/solutions/virtual-production.vue?macro=true";
import { default as termsvHW4ELNWaAMeta } from "/home/vsts/work/1/s/zenapptic-website-nuxt/pages/terms.vue?macro=true";
export default [
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/home/vsts/work/1/s/zenapptic-website-nuxt/pages/contact-us.vue")
  },
  {
    name: "creative-services",
    path: "/creative-services",
    component: () => import("/home/vsts/work/1/s/zenapptic-website-nuxt/pages/creative-services.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/vsts/work/1/s/zenapptic-website-nuxt/pages/index.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/home/vsts/work/1/s/zenapptic-website-nuxt/pages/privacy.vue")
  },
  {
    name: "solutions-digital-workplace",
    path: "/solutions/digital-workplace",
    component: () => import("/home/vsts/work/1/s/zenapptic-website-nuxt/pages/solutions/digital-workplace.vue")
  },
  {
    name: "solutions-experience-centers",
    path: "/solutions/experience-centers",
    component: () => import("/home/vsts/work/1/s/zenapptic-website-nuxt/pages/solutions/experience-centers.vue")
  },
  {
    name: "solutions-experiential-spaces",
    path: "/solutions/experiential-spaces",
    component: () => import("/home/vsts/work/1/s/zenapptic-website-nuxt/pages/solutions/experiential-spaces.vue")
  },
  {
    name: "solutions-virtual-production",
    path: "/solutions/virtual-production",
    component: () => import("/home/vsts/work/1/s/zenapptic-website-nuxt/pages/solutions/virtual-production.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/home/vsts/work/1/s/zenapptic-website-nuxt/pages/terms.vue")
  }
]